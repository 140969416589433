@import "./assets/css/fonts.scss";
@import "./jiggle.scss";

* {
    font-optical-sizing: auto;
    scrollbar-width: none;
}

[data-radix-popper-content-wrapper] {
    z-index: 9999 !important;
}

.markdown {

body {
    font-size: 12pt; /* standard size for academic papers */
    line-height: 1.4; /* for readability */
    color: #212121; /* softer on the eyes than black */
    background-color: #fff; /* white background */
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    color: #212121; /* darker than the body text */
    margin-top: 1em; /* space above headers */
}

h1 {
    font-size: 1.5em; /* make h1 twice the size of the body text */
    font-weight: bold;
}

h2 {
    font-size: 1.2em;
    font-weight: bold;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px;
}

h3 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

/* Add some space between paragraphs */
p {
    margin-bottom: 1em;
}

/* Style for blockquotes, often used in academic papers */
blockquote {
    font-style: italic;
    margin: 1em 0;
    padding: 1em;
    background-color: #f9f9f9; /* a light grey background */
}

/* You might want to style tables, figures, etc. too */
table {
    border-collapse: collapse;
    width: 100%;
}

table, th, td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
    color: black;
}
}

@supports selector(:has(*)) {
    .chakra-portal-zIndex:has(#chakra-toast-manager-top) {
      z-index: 9999 !important;
    }
  }

  .jiggle {
    border-radius: 50%;
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jiggle-bar {
    border: 1.5px solid white;
    transition: all 0.25s;
    height: 2px;
    width: 2px;
    background-color: white;
    margin-left: 0.1em;
    margin-right: 0.1em;
    border-radius: 10px;
}

.jiggle-xl {
    width: 200px;
    height: 200px;
}

.jiggle-xl .jiggle-bar {
    background-color: black;
    border: 1.5px solid black;
    width: 30px;
    border-radius: 24px;
}